<template>
  <v-menu v-model="showMenu" v-bind="menuOptions" :close-on-content-click="false" :nudge-left="100" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        autocomplete="off"
        v-bind="{ ...$attrs, ...attrs }"
        v-model="formattedDate"
        prepend-icon="mdi-calendar"
        :error-messages="dateError"
        v-on="on"
        :readonly="$vuetify.breakpoint.mobile"
        dense
        hide-details="auto"
      ></v-text-field>
    </template>
    <v-date-picker no-title v-bind="{ ...$util.localizeCalendar(), ...pickerOptions }" v-model="isoDate" @input="showMenu = false" />
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'datePicker',
  props: {
    value: String,
    menuOptions: Object,
    pickerOptions: Object,
  },
  data: () => ({
    showMenu: false,
    dateError: null,
  }),
  computed: {
    formattedDate: {
      get() {
        return this.value ? DateTime.fromISO(this.value).toFormat('dd-MM-yyyy') : null;
      },
      set(val) {
        this.showMenu = false;
        if (val) {
          let newTime = DateTime.fromFormat(val, 'd-M-yyyy');
          if (!newTime.isValid) {
            newTime = DateTime.fromFormat(val, 'yyyy-M-d');
          }

          if (newTime.isValid) {
            this.dateError = null;
            this.$emit('input', newTime.toISODate());
          } else {
            this.dateError = 'Dato ikke gyldig (DD-MM-ÅÅÅÅ)';
          }
        }
      },
    },
    isoDate: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {},
};
</script>
