<template>
  <v-navigation-drawer
    temporary
    fixed
    :width="detailsView || isEditing || !showSecondSection ? '60vw' : '85vw'"
    color="drawerBackground"
    v-model="show"
    right
  >
    <v-sheet class="mx-auto" height="100vh">
      <v-row no-gutters class="">
        <r-btn class="ml-auto" icon @click="close">
          <v-icon dark>mdi-close-thick</v-icon>
        </r-btn>
      </v-row>
      <v-row no-gutters class="d-flex">
        <v-container style="height: 95vh" fluid class="d-flex flex-row">
          <!-- Section 1 -->
          <v-col cols="12" :md="detailsView || isEditing ? '4' : '3'" class="mt-16 px-10">
            <v-row no-gutters justify="center">
              <v-icon color="logo_Red" size="125"> mdi-calendar-month </v-icon>
            </v-row>
            <v-row no-gutters align="center" class="d-flex flex-column" v-if="currentEvent">
              <p class="text-h5 ma-0">{{ !detailsView && !isEditing ? 'Ny aftale' : !detailsView && isEditing ? 'Redigere' : null }}<br /></p>
              <p class="text-h5 ma-0" v-if="detailsView || isEditing">{{ $util.LessonTypeFromIdToText(currentEvent.type) }} Aftale</p>
            </v-row>
            <v-row no-gutters v-if="currentEvent && currentEvent.status != 2">
              <r-btn class="mx-auto" @click="isEditing = !isEditing" v-if="detailsView">Rediger<v-icon>mdi-pencil</v-icon></r-btn>
              <r-btn class="mx-auto" text @click="isEditing = !isEditing" v-if="isEditing">Annuller Redigering<v-icon>mdi-pencil</v-icon></r-btn>
            </v-row>
            <v-row no-gutters class="py-4">
              <r-btn
                class="mx-auto"
                delete
                :block="$vuetify.breakpoint.mobile"
                @click="removeEvent"
                v-if="detailsView && !hasStudentEvents && !isRemoved && !hasBeenSigned"
              >
                Slet Begivenhed
              </r-btn>
            </v-row>
            <v-row no-gutters class="py-4" v-if="hasStudentEvents && !hasBeenSigned">
              <r-btn class="mx-auto" delete :block="$vuetify.breakpoint.mobile" @click="cancelEvent" v-if="detailsView || isEditing">
                Aflys Aftale</r-btn
              >
            </v-row>
            <v-row no-gutters class="mt-16">
              <v-col cols="12" class="text-center" v-if="(detailsView || isEditing) && currentEvent.type != 3">
                <h4>{{ event_StudentName ? `Tiden er booket af:` : '' }}</h4>
                <h4>{{ event_StudentName ? `${event_StudentName}` : '' }}</h4>
                <br />
                <h4>
                  {{ currentEvent && currentEvent.status == 2 ? 'Gennemført' : currentEvent.status == 3 ? 'Begivenhed fjernet fra kalender' : '' }}
                </h4>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical inset class="thickDivider" />
          <!-- Section 2 -->
          <v-col cols="12" md="4" class="text-center mt-16 px-4" v-if="!detailsView && !isEditing && showSecondSection">
            <v-row no-gutters class="justify-center">
              <h3 class="grey--text">Vælg undervisningstype</h3>
            </v-row>
            <v-row no-gutters class="justify-center my-8">
              <r-btn
                style="text-transform: none"
                :color="chosenType == 2 ? 'activeButton' : 'grey'"
                outlined
                x-large
                height="15vh"
                width="70%"
                :disabled="detailsView && this.currentEvent.type != 2"
                @click="chosenType = 2"
              >
                <v-row no-gutters dense class="">
                  <v-col cols="12" class="my-5">
                    <h4>Ny praktisk lektion</h4>
                  </v-col>
                  <v-col class="mx-auto text-wrap" cols="4" lg="6">
                    <p class="subtitle-2">Opret praktiske lektioner eleverne kan booke</p>
                  </v-col>
                </v-row>
              </r-btn>
            </v-row>
            <v-row no-gutters class="justify-center my-8">
              <r-btn
                style="text-transform: none"
                :color="chosenType == 4 ? 'activeButton' : 'grey'"
                outlined
                x-large
                height="15vh"
                width="70%"
                :disabled="detailsView && this.currentEvent.type != 4"
                @click="chosenType = 4"
              >
                <v-row no-gutters dense class="" style="max-width: 35vw">
                  <v-col cols="12" class="my-5">
                    <h4>Ny praktisk Repetition 7.1 - 7.21</h4>
                  </v-col>
                  <v-col class="mx-auto text-wrap" cols="4" lg="6">
                    <p class="subtitle-2">Opret begivenheder for repetition 7.1 - 7.21 som eleverne kan se i deres app</p>
                  </v-col>
                </v-row>
              </r-btn>
            </v-row>
            <v-row no-gutters class="justify-center my-8" v-if="!isEditing">
              <r-btn
                style="text-transform: none"
                :color="chosenType == 3 ? 'activeButton' : 'grey'"
                outlined
                x-large
                height="15vh"
                width="70%"
                :disabled="detailsView && this.currentEvent.type != 3"
                @click="chosenType = 3"
              >
                <v-row no-gutters dense class="">
                  <v-col cols="12" class="my-5">
                    <h4>Ny prøve</h4>
                  </v-col>
                  <v-col class="mx-auto text-wrap" cols="4" lg="6">
                    <p class="subtitle-2">Tilføj en køreprøve til elevens kalender</p>
                  </v-col>
                </v-row>
              </r-btn>
            </v-row>
            <v-row no-gutters class="justify-center my-8" v-if="!isEditing">
              <r-btn
                style="text-transform: none"
                :color="chosenType == 6 ? 'activeButton' : 'grey'"
                outlined
                x-large
                height="15vh"
                width="70%"
                :disabled="detailsView && this.currentEvent.type != 6"
                @click="chosenType = 6"
              >
                <v-row no-gutters dense class="">
                  <v-col cols="12" class="my-5">
                    <h4>Privat aftale</h4>
                  </v-col>
                  <v-col class="mx-auto text-wrap" cols="4" lg="6">
                    <p class="subtitle-2">Tilføj en privat aftale til din kalender</p>
                  </v-col>
                </v-row>
              </r-btn>
            </v-row>
          </v-col>
          <v-divider vertical inset class="thickDivider" />
          <!-- Section 3 -->
          <v-col cols="12" :md="detailsView || isEditing || !showSecondSection ? '8' : '4'" v-if="chosenType" class="text-center mx-auto mt-16">
            <v-form ref="form" v-model="isValid">
              <v-container fluid>
                {{ lessonTypeDescription }}
                <v-row no-gutters v-if="showStudentSelector" class="my-4">
                  <v-col cols="12" md="4" align-self="center">Elev:*</v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="students"
                      outlined
                      v-model="chosenStudent"
                      item-text="name"
                      return-object
                      placeholder="Elev"
                      hide-details="auto"
                      dense
                      :disabled="detailsView"
                      @change="getModules()"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="chosenType != 4" v-show="chosenType < 3" class="my-4">
                  <v-col cols="12" md="4" align-self="center">Modul:</v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      v-if="currentEvent"
                      :items="moduleList"
                      outlined
                      @change="moduleChanged"
                      v-model="selectedModule"
                      return-object
                      item-text="name"
                      :rules="chosenType == 1 ? [rules.required] : []"
                      placeholder="Modul"
                      hide-details="auto"
                      dense
                      :disabled="detailsView || (isEditing && repeatingEvent)"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="isAdmin && chosenType != 3" class="my-4">
                  <v-col cols="12" md="4" align-self="center">Instruktør:*</v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="instructors"
                      outlined
                      v-model="currentEvent.instructorId"
                      item-value="id"
                      item-text="name"
                      :rules="[rules.required]"
                      placeholder="Instruktør"
                      hide-details="auto"
                      dense
                      :disabled="detailsView"
                    />
                  </v-col>
                </v-row>
                <!-- <v-row no-gutters v-if="chosenType == 1 && theoryTeams.length > 0" class="my-4">
                  <v-col cols="12" md="4" align-self="center">Teorihold:</v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="theoryTeams"
                      outlined
                      v-model="currentEvent.theoryTeamId"
                      item-value="id"
                      item-text="name"
                      placeholder="Teorihold"
                      hide-details="auto"
                      dense
                      :disabled="detailsView"
                      clearable
                    />
                  </v-col>
                </v-row> -->
                <v-row no-gutters v-if="!repeatingEvent" class="my-4">
                  <v-col cols="12" md="4" align-self="center">Dato & tid:</v-col>
                  <v-col cols="12" md="4" class="pr-2">
                    <DatePicker :rules="rules.dateRules" v-model="date" placeholder="Dato" :disabled="detailsView" />
                  </v-col>
                  <v-col class="d-flex" cols="12" md="4">
                    <v-text-field
                      class="pr-1"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="rules.timeRules"
                      @change="setEndTimeDefaultValue"
                      v-model="startTime"
                      placeholder="Start"
                      :disabled="detailsView"
                    ></v-text-field>
                    <v-text-field
                      class="pl-1"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="rules.timeRules"
                      v-model="endTime"
                      placeholder="Slut"
                      :disabled="detailsView"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="my-4" v-if="chosenType == 2 || chosenType == 1">
                  <v-col cols="12" md="8" offset-md="4">
                    <v-checkbox
                      class="ma-0 pa-0"
                      color="activeButton"
                      v-model="repeatingEvent"
                      :label="detailsView || isEditing ? 'Gentagende begivenhed' : 'Gentag Begivenhed'"
                      dense
                      hide-details="auto"
                      :disabled="detailsView || isEditing"
                    />
                  </v-col>
                </v-row>
                <div v-if="repeatingEvent">
                  <v-row v-if="repeatingEvent">
                    <v-col cols="12" md="4" align-self="center">Begivenheds dato: </v-col>
                    <v-col cols="12" md="8" class="pr-2">
                      <DatePicker v-model="date" placeholder="Begivenheds dato" :disabled="detailsView" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="my-4" v-if="!isEditing && !detailsView">
                    <v-col cols="12" md="4" align-self="center"> Ugedag:* </v-col>
                    <v-col cols="12" md="8">
                      <v-select
                        :items="weekDays"
                        outlined
                        v-model="selectedWeekday"
                        :rules="[rules.required]"
                        placeholder="Ugedag"
                        hide-details="auto"
                        dense
                        :disabled="detailsView"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="my-4">
                    <v-col cols="12" md="4" align-self="center"> Start og slut-tidspunkt: </v-col>
                    <v-col cols="6" md="4" class="pr-2">
                      <v-text-field
                        outlined
                        dense
                        hide-details="auto"
                        :rules="rules.timeRules"
                        @change="setEndTimeDefaultValue"
                        v-model="startTime"
                        placeholder="Start"
                        :disabled="detailsView"
                      />
                    </v-col>
                    <v-col cols="6" md="4" class="pl-2">
                      <v-text-field
                        outlined
                        dense
                        hide-details="auto"
                        :rules="rules.timeRules"
                        v-model="endTime"
                        placeholder="Slut"
                        :disabled="detailsView"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="my-4" v-if="!detailsView && !isEditing">
                    <v-col cols="12" md="4" align-self="center">Start og slut-dato: </v-col>
                    <v-col cols="6" md="4" class="pr-2">
                      <DatePicker :rules="rules.dateRules" v-model="startDate" placeholder="Fra" />
                    </v-col>
                    <v-col cols="6" md="4" class="pl-2">
                      <DatePicker :rules="rules.dateRules" v-model="endDate" placeholder="Til" />
                    </v-col>
                  </v-row>
                </div>
                <v-row v-if="isPrivateAppointment" class="my-4">
                  <v-col cols="12" md="4" align-self="center">Titel:*</v-col>
                  <v-col>
                    <v-text-field
                      v-model="currentEvent.comment"
                      :rules="[rules.required]"
                      outlined
                      dense
                      hide-details
                      :disabled="detailsView || (repeatingEvent && isEditing)"
                    />
                  </v-col>
                </v-row>
                <v-row v-else no-gutters class="my-4">
                  <v-col cols="12" md="4" :class="$vuetify.breakpoint.mobile ? 'mb-2' : ''" align-self="center">Kommentar</v-col>
                  <v-col>
                    <v-textarea
                      v-model="currentEvent.comment"
                      outlined
                      hide-details="auto"
                      dense
                      no-resize
                      :disabled="detailsView || (repeatingEvent && isEditing)"
                    />
                  </v-col>
                </v-row>
                <v-row class="my-4" no-gutters v-if="error != ''">
                  <v-col>
                    <h3 style="color: #f44336">{{ error }}</h3>
                  </v-col>
                </v-row>
                <p class="text-caption">Felter med asterisk (*) er obligatoriske.</p>
                <v-row class="d-flex" justify-lg="end" no-gutters v-if="!detailsView">
                  <v-col cols="12" lg="6" class="mb-md-2 text-right mr-4">
                    <r-btn :block="$vuetify.breakpoint.mobile" @click="close">Annuller</r-btn>
                  </v-col>
                  <v-col cols="12" lg="auto" class="text-right">
                    <r-btn :block="$vuetify.breakpoint.mobile" creation :loading="isSaving" :disabled="!isValid" @click="saveCurrentCalenderEvent">
                      <v-icon left>mdi-check</v-icon>
                      {{ isEditing ? 'Gem ændringer' : 'Opret' }}
                    </r-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-container>
      </v-row>
    </v-sheet>
    <confirmation-dialog ref="confirmationDialogRef" />
  </v-navigation-drawer>
</template>

<script>
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';
import DatePicker from '@/components/datePicker.vue';
import { DateTime } from 'luxon';
import ConfirmationDialog from './ConfirmationDialog.vue';
import _ from 'lodash';

export default {
  name: 'CalendarPanel',
  components: { DatePicker, ConfirmationDialog },
  data: () => ({
    shouldEditList: false,

    isEditing: false,
    isValid: false,
    isLoading: false,
    isSaving: false,
    show: false,
    showSecondSection: true,
    date: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,

    currentEvent: {
      moduleId: '',
    },
    selectedModule: null,

    chosenStudent: null,
    chosenType: 0,
    selectedWeekday: 0,

    repeatingEvent: false,

    error: '',

    event_StudentName: '',
    instructors: [],
    studentList: [],
    theoryTeams: [],
    modules: [],

    weekDays: [
      {
        text: 'Mandag',
        value: 1,
      },
      {
        text: 'Tirsdag',
        value: 2,
      },
      {
        text: 'Onsdag',
        value: 3,
      },
      {
        text: 'Torsdag',
        value: 4,
      },
      {
        text: 'Fredag',
        value: 5,
      },
      {
        text: 'Lørdag',
        value: 6,
      },
      {
        text: 'Søndag',
        value: 7,
      },
    ],

    rules: {
      required: value => !!value || 'Du skal indtaste en værdi.',

      timeRules: [
        v => !!v || 'Du skal indtaste et tidspunkt',
        v => v?.length > 4 || 'Tidspunkt skal være mindst 4 tegn',
        v => /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Tidspunkt er ikke gyldig. (TT:mm 24t) ',
      ],
      dateRules: [v => !!v || 'Du skal indtaste en dato'],
    },
  }),
  watch: {
    isEditing: {
      // This is made to prevent issues where some user written data will get saved in cache
      immediate: true,
      handler(val) {
        if (!val && this.currentEvent.id != null) {
          this.getEventDetails(this.currentEvent.id);
        }
      },
    },
    chosenType: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val != 0) {
          this.currentEvent.moduleId == '';
          this.getDefaultData();
        }
      },
    },
  },
  computed: {
    ...mapGetters(['globalAlert', 'darkMode', 'currentOrgId', 'isAdmin', 'user']),
    detailsView() {
      if (this.currentEvent.id && !this.isEditing) {
        return true;
      } else {
        return false;
      }
    },

    students() {
      if (this.studentList?.length > 0) {
        return this.studentList;
      }
      return [];
    },

    hasBeenSigned() {
      if (this.currentEvent.studentEvents?.length > 0) {
        if (this.currentEvent.studentEvents.some(x => x.studentSignature != null && x.instructorSignatur != null)) {
          return true;
        } else return false;
      } else return false;
    },

    moduleList() {
      if (this.chosenType == 2) {
        let filterModules = this.modules.filter(x => x.lessonType == 2);
        return filterModules;
      }
      if (this.chosenType == 3) {
        return [
          {
            description: null,
            moduleId: '',
            isOptional: null,
            lessonType: 2,
            name: 'Køreprøve',
            order: null,
            organizationId: null,
          },
        ];
      }
      return [];
    },

    isPrivateAppointment() {
      return this.chosenType == 6;
    },

    showStudentSelector() {
      if (this.chosenType == 1) {
        return false;
      }

      if (this.chosenType == 2 && this.currentEvent.moduleId == '') {
        return false;
      }

      if (this.chosenType == 6) {
        return false;
      }

      return true;
    },

    lessonTypeDescription() {
      switch (this.chosenType) {
        case 1:
          return 'TEORI';

        case 2:
          return 'PRAKTISK';

        case 3:
          return 'KØREPRØVE';

        case 4:
          return 'REPETITION 7.1 - 7.21';

        case 5:
          return 'REPETITION';

        case 6:
          return 'PRIVAT AFTALE';

        default:
          return `UKENDT ${this.chosenType}`;
      }
    },

    isRemoved() {
      return this.currentEvent.status == 3 ? true : false;
    },

    hasStudentEvents() {
      if (this.currentEvent.studentEvents?.length > 0) return true;
      return false;
    },

    studentEvents() {
      return this.hasStudentEvents ? this.currentEvent.studentEvents : null;
    },
  },
  methods: {
    async getStudents() {
      this.studentList = await apiService.getStudentList(this.currentOrgId);

      this.studentList.unshift({
        id: null,
        name: 'Ingen elev',
        description: 'Vælg for ikke at have en elev',
      });
      this.chosenStudent = _.first(this.studentList);
    },

    async getEventDetails(event_id) {
      await this.getDefaultData();
      let _event = await apiService.getEventDetails(event_id);
      this.currentEvent = _event;
      this.chosenType = this.currentEvent.type;
      this.repeatingEvent = this.currentEvent.eventListId != null ? true : false;
      this.date = DateTime.fromISO(this.currentEvent.start).toFormat('yyyy-MM-dd');
      this.startTime = DateTime.fromISO(this.currentEvent.start).toFormat('HH:mm');
      this.endTime = DateTime.fromISO(this.currentEvent.end).toFormat('HH:mm');

      if (this.hasStudentEvents) {
        await this.getStudents();
        let studentEvent = this.currentEvent.studentEvents[0];
        let event_Student = await apiService.getStudentById(studentEvent.studentId);
        this.event_StudentName = event_Student.name;
        this.chosenStudent = event_Student;
        await this.getModules();
        this.selectedModule = _event.module;
      }
    },

    async showEvent(event) {
      this.currentEvent = {};
      await this.getEventDetails(event.id);

      this.show = true;
    },

    async createNewEvent(start) {
      this.clearDefaultData();
      await this.getDefaultData();

      this.date = DateTime.fromJSDate(start).toISODate();
      this.show = true;
    },

    async createDeterminedEvent(eventModuleId, startTime) {
      this.clearDefaultData();

      this.date = DateTime.fromISO(startTime).toISODate();
      this.startTime = DateTime.fromISO(startTime).toFormat('HH:mm');

      this.startDate = this.date;
      this.endDate = DateTime.fromISO(startTime).plus({ days: 1 }).toISODate();

      if (eventModuleId === 'openSlot') {
        this.chosenType = 2;
        this.endTime = DateTime.fromISO(startTime).plus({ minutes: 90 }).toFormat('HH:mm');
      } else {
        this.chosenType = 1;
      }

      if (this.startTime == '00:00') {
        this.startTime = null;
        this.endTime = null;
      }

      await this.getDefaultData();
      this.isValid = true;

      this.selectedModule = eventModuleId == 'openSlot' ? this.selectedModule : this.modules.find(x => x.moduleId == eventModuleId);

      this.selectedWeekday = DateTime.fromISO(startTime).weekday;

      this.showSecondSection = false;
      this.show = true;
    },

    async cancelEvent() {
      let dialogResponse;
      if (this.repeatingEvent) {
        try {
          dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
            title: 'Aflys aftale',
            body: `Ønsker du at aflyse hele serien af aftaler?`,
            cancelBtn: 'Kun denne',
            okBtn: 'Hele serien',
          });
        } catch (error) {
          if (error.Error.toLowerCase() == 'cancel') {
            dialogResponse = 'cancel';
          } else {
            console.log('Error: ', error);
          }
        }
        if (dialogResponse === 'cancel') {
          this.$refs.confirmationDialogRef.hideDialog();
          return (this.isEditing = false);
        } else if (dialogResponse) {
          await apiService.cancelEventList(this.currentEvent.eventListId);
          this.showSnackBar(`Begivenheder blev aflyst`, 'warning');
        } else {
          await apiService.cancelEvent(this.currentEvent.id);
          this.showSnackBar(
            `Begivenhed blev aflyst`,
            this.event_StudentName
              ? `Elev: ${this.event_StudentName} ${this.date ? '<br/>' + this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`
              : `${this.date ? this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`,
            'warning',
          );
        }
      } else {
        dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
          title: 'Aflys aftale',
          body: `Ønsker du at aflyse denne aftale?`,
          studentName: `${this.event_StudentName ? 'Elev: ' + this.event_StudentName : ''}`,
          type: `Type: ${this.$util.LessonTypeFromIdToText(this.currentEvent.type)}`,
          okBtn: 'Aflys',
        });
        if (dialogResponse) {
          await apiService.cancelEvent(this.currentEvent.id);
          this.showSnackBar(
            `Begivenhed blev aflyst`,
            this.event_StudentName
              ? `Elev: ${this.event_StudentName} ${this.date ? '<br/>' + this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`
              : `${this.date ? this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`,
            'warning',
          );
          this.show = false;
          this.clearDefaultData();
          this.$emit('update-calender');
        } else {
          this.isEditing = false;
        }
        this.$refs.confirmationDialogRef.hideDialog();
      }
    },

    async removeEvent() {
      let dialogResponse;
      if (this.repeatingEvent) {
        try {
          dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
            title: 'Fjern begivenhed',
            body: `Ønsker du at fjerne hele serien af begivenheder?`,
            cancelBtn: 'Kun denne',
            okBtn: 'Hele serien',
          });
        } catch (error) {
          if (error.Error.toLowerCase() == 'cancel') {
            dialogResponse = 'cancel';
          } else {
            console.log('Error: ', error);
          }
        }
        if (dialogResponse === 'cancel') {
          this.$refs.confirmationDialogRef.hideDialog();
          return (this.isEditing = false);
        } else if (dialogResponse) {
          await apiService.removeEventList(this.currentEvent.eventListId);
          this.showSnackBar(`Begivenheder blev fjernet`, '', 'warning');
        } else {
          await apiService.removeEvent(this.currentEvent.id);
          this.showSnackBar(
            `Begivenhed blev fjernet`,
            this.event_StudentName
              ? `Elev: ${this.event_StudentName} ${this.date ? '<br/>' + this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`
              : `${this.date ? this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`,
            'warning',
          );
        }
      } else {
        dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
          title: 'Fjern begivenhed',
          body: `Ønsker du at fjerne denne begivenhed?`,
          studentName: `${this.event_StudentName ? 'Elev: ' + this.event_StudentName : ''}`,
          type: `Type: ${this.$util.LessonTypeFromIdToText(this.currentEvent.type)}`,
          okBtn: 'Fjern',
        });
        if (dialogResponse) {
          await apiService.removeEvent(this.currentEvent.id);
          this.showSnackBar(
            `Begivenhed blev fjernet`,
            this.event_StudentName
              ? `Elev: ${this.event_StudentName} ${this.date ? '<br/>' + this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`
              : `${this.date ? this.date + ' - ' + this.startTime + ' til ' + this.endTime : ''}`,
            'warning',
          );
        } else {
          this.$refs.confirmationDialogRef.hideDialog();
          return (this.isEditing = false);
        }
      }
      this.clearDefaultData();
      this.show = false;
      this.$emit('update-calender');
      this.$refs.confirmationDialogRef.hideDialog();
    },

    close() {
      this.clearDefaultData();
      this.show = false;
    },

    buildTimestamp(date, time) {
      return DateTime.fromFormat(`${date} ${time}`, 'yyyy-MM-dd H:m').toISO();
    },

    async saveCurrentCalenderEvent() {
      this.isSaving = true;

      const dateChanged = DateTime.fromISO(this.currentEvent.start).toFormat('yyyy-MM-dd') !== this.date;
      this.currentEvent.start = this.buildTimestamp(this.date, this.startTime);
      this.currentEvent.end = this.buildTimestamp(this.date, this.endTime);

      this.currentEvent.organizationId = this.currentOrgId;
      this.currentEvent.type = this.currentEvent.type ? this.currentEvent.type : this.chosenType;

      if (this.currentEvent.type != 4 && this.currentEvent.type != 6) {
        this.currentEvent.moduleId = this.selectedModule.moduleId ?? this.currentEvent.module.id;
      }

      this.chosenStudent ? (this.currentEvent.studentId = this.chosenStudent.id) : null;

      if (this.currentEvent.type == 3 || this.currentEvent.type == 4) {
        this.currentEvent.moduleId = null;
      }

      if (this.currentEvent.start > this.currentEvent.end) {
        this.error = 'Slut tidspunkt skal være senere end start tidspunkt';
        this.isSaving = false;
      }

      if (this.startDate > this.endDate) {
        this.error = 'Slut dato skal være senere end start dato';
        this.isSaving = false;
      }

      if ((this.currentEvent.type == 2 && this.currentEvent.type == 1) || this.repeatingEvent) {
        if (this.isEditing) {
          // Returns promise, which then becomes a true or false depending on users choice.
          // If needed we can return a rejectPromise.
          let dialogResponse;

          if (!dateChanged) {
            try {
              dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
                title: 'Gentagende begivenhed',
                body: 'Ønsker du at foretage denne ændring på hele serien af begivenheder',
                cancelBtn: 'Kun denne',
                okBtn: 'Hele serien',
              });
            } catch (error) {
              if (error.Error.toLowerCase() == 'cancel') {
                dialogResponse = 'cancel';
              } else {
                console.log('Error: ', error);
              }
            }
          } else {
            // if date has been modified for a recurrent event, do not prompt to modify entire series
            // as that does not make sense
            dialogResponse = false;
          }

          this.$refs.confirmationDialogRef.hideDialog();

          if (dialogResponse === 'cancel') {
            // If user didn't mean to edit current event revert the changes.
            this.isSaving = false;
            this.isEditing = false;
            this.showSnackBar('Ændring annulleret', ``, 'warning');
            this.getEventDetails(this.currentEvent.id);
          } else if (dialogResponse) {
            const dateTimeObject = {
              start: DateTime.fromISO(this.currentEvent.start),
              end: DateTime.fromISO(this.currentEvent.end),
            };
            await apiService
              .updateEventsByEventListId(dateTimeObject, this.currentEvent.eventListId)
              .then(x => {
                this.getEventDetails(x);
                this.$emit('update-calender');
              })
              .finally(() => {
                this.isSaving = false;
                this.isEditing = false;
                this.show = false;
                this.showSnackBar(
                  'Gentagende begivenhed opdateret',
                  `Start tid: ${DateTime.fromISO(dateTimeObject.start).toFormat('HH:mm')} <br/>
                  Slut tid: ${DateTime.fromISO(dateTimeObject.end).toFormat('HH:mm')}`,
                  'success',
                );
              });
          } else {
            await apiService
              .updateEvent(this.currentEvent)
              .then(x => {
                this.getEventDetails(x);
                this.$emit('update-calender');
              })
              .finally(() => {
                this.isSaving = false;
                this.isEditing = false;
                this.show = false;
                this.showSnackBar(
                  `Begivenhed opdateret`,
                  `Dato: ${DateTime.fromISO(this.currentEvent.start).toFormat('dd-MM-yyyy')} <br/>
                  Start tid: ${DateTime.fromISO(this.currentEvent.start).toFormat('HH:mm')} <br/>
                  Slut tid: ${DateTime.fromISO(this.currentEvent.end).toFormat('HH:mm')}`,
                  'success',
                );
              });
          }
        } else {
          this.eventListId = await apiService.updateEventList({
            OrganizationId: this.currentOrgId,
          });

          for (let i = 0; DateTime.fromISO(this.startDate).plus({ days: i }) < DateTime.fromISO(this.endDate); i++) {
            if (DateTime.fromISO(this.startDate).plus({ days: i }).weekday == this.selectedWeekday) {
              await apiService
                .updateEvent({
                  ...this.currentEvent,
                  eventListId: this.eventListId,
                  start: DateTime.fromFormat(`${this.startDate} ${this.startTime}`, 'yyyy-MM-dd H:m').plus({ days: i }).toISO(),
                  end: DateTime.fromFormat(`${this.startDate} ${this.endTime}`, 'yyyy-MM-dd H:m').plus({ days: i }).toISO(),
                })
                .then(() => {
                  this.$emit('update-calender');
                })
                .finally(() => {
                  this.isSaving = false;
                  this.isEditing = false;
                  this.show = false;
                  this.showSnackBar(
                    'Gentagende begivenhed oprettet',
                    `Hver ${this.weekDays[this.selectedWeekday - 1].text} <br/>
                    Fra ${DateTime.fromISO(this.startDate).toFormat('dd-LLL')} <br/>
                    Til ${DateTime.fromISO(this.endDate).toFormat('dd-LLL')} <br/>
                    Start tid: ${DateTime.fromISO(this.startTime).toFormat('HH:mm')} <br/>
                    Slut tid: ${DateTime.fromISO(this.endTime).toFormat('HH:mm')}`,
                    'success',
                  );
                });
            }
          }
          this.show = false;
          this.$emit('update-calender');
          this.isSaving = false;
        }
      } else {
        await apiService
          .updateEvent(this.currentEvent)
          .then(x => {
            this.getEventDetails(x);
            this.$emit('update-calender');
          })
          .finally(() => {
            if (this.isEditing) {
              this.showSnackBar(
                `Begivenhed opdateret`,
                `Dato: ${DateTime.fromISO(this.currentEvent.start).toFormat('dd-MM-yyyy')} <br/>
                  Start tid: ${DateTime.fromISO(this.currentEvent.start).toFormat('HH:mm')} <br/>
                  Slut tid: ${DateTime.fromISO(this.currentEvent.end).toFormat('HH:mm')}`,
                'success',
              );
            } else {
              this.showSnackBar(
                `Begivenhed oprettet`,
                `Dato: ${DateTime.fromISO(this.currentEvent.start).toFormat('dd-MM-yyyy')} <br/>
                  Start tid: ${DateTime.fromISO(this.currentEvent.start).toFormat('HH:mm')} <br/>
                  Slut tid: ${DateTime.fromISO(this.currentEvent.end).toFormat('HH:mm')}`,
                'success',
              );
            }
            this.isSaving = false;
            this.isEditing = false;
            this.show = false;
          });
      }
      this.clearDefaultData();
    },
    async getModules() {
      this.modules = [];
      if (this.currentOrgId) {
        let response;
        let formattedModules;
        this.modules = [];

        if (this.chosenStudent?.id != null) {
          response = await apiService.getModuleListOfUser(this.chosenStudent.id);
        }
        if (this.chosenType == 2 && this.chosenStudent?.id == null) {
          this.modules = [
            {
              description: null,
              moduleId: '',
              isOptional: null,
              lessonType: 2,
              name: 'Åben tid',
              order: null,
              organizationId: null,
            },
          ];
        }
        if (response != null) {
          formattedModules = response.modules.map(x => {
            return {
              description: x.description,
              moduleId: x.id,
              isOptional: x.isOptional,
              lessonType: x.lessonType,
              name: x.name,
              order: x.order,
              organizationId: x.organizationId,
              length: x.minimumDuration * 45,
            };
          });

          this.modules = this.modules.concat(formattedModules);

          if (this.chosenType == 3) {
            this.modules = [
              {
                description: null,
                moduleId: '',
                isOptional: null,
                lessonType: 2,
                name: 'Køreprøve',
                order: null,
                organizationId: null,
              },
            ];
          }
        }
        this.selectedModule = this.modules[0];
      }
    },
    async getInstructorsById() {
      if (this.currentOrgId) {
        this.instructors = await apiService.getStaffListByOrgId(this.currentOrgId);
        if (this.instructors?.length > 0 && (!this.currentEvent?.instructorId || this.currentEvent.instructorId == '')) {
          this.currentEvent.instructorId = this.instructors.some(x => x.id == this.user.id) ? this.user.id : this.instructors[0].id;
        }
      }
    },
    setEndTimeDefaultValue() {
      this.endTime = DateTime.fromFormat(this.startTime, 'HH:mm').plus({ minutes: 90 }).toFormat('HH:mm');
    },
    showSnackBar(title, body, color) {
      this.$store.commit('alert', {
        show: true,
        title: title,
        color: color,
        textColor: 'white',
        message: body,
      });
    },

    clearDefaultData() {
      this.isEditing = false;
      this.isValid = false;
      this.isLoading = false;
      this.isSaving = false;
      this.date = null;
      this.startDate = null;
      this.endDate = null;
      this.startTime = null;
      this.endTime = null;
      this.currentEvent = {};
      this.chosenType = 0;
      this.selectedWeekday = 0;
      this.repeatingEvent = false;
      this.error = '';
      this.event_StudentName = '';
      this.instructors = [];
      this.modules = [];
      this.theoriTeams = [];
      this.studentList = [];
      this.chosenStudent = null;
      this.showSecondSection = true;
    },
    async getDefaultData() {
      await this.getStudents();
      if (this.date == null) this.date = DateTime.now().toISODate();
      if (this.startTime == null) this.startTime = DateTime.now().toFormat('HH:mm');
      if (this.endTime == null) this.endTime = DateTime.now().plus({ minutes: 90 }).toFormat('HH:mm');
      if (this.startDate == null) this.startDate = DateTime.now().toISODate();
      if (this.endDate == null) this.endDate = DateTime.now().plus({ days: 1 }).toISODate();

      await this.getModules();
      await this.getInstructorsById();
      // await this.getTheoryTeamList();
      this.moduleChanged();
    },
    moduleChanged() {
      if (this.selectedModule != null) {
        this.currentEvent.moduleId = this.selectedModule.moduleId;

        if (this.selectedModule?.length) {
          this.endTime = DateTime.fromFormat(this.startTime, 'HH:mm').plus({ minutes: this.selectedModule?.length }).toFormat('HH:mm');
        }
      }
    },
  },
};
</script>

<style>
.smallTextTabs {
  font-size: 0.8rem !important;
}
.thickDivider {
  border-width: 1px !important;
}
</style>
