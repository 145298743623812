
<template>
  <v-container class="d-flex" fluid>
    <v-sheet :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'" class="mr-4">
      <v-sheet color="navbar">
        <v-row no-gutters class="py-4 px-2">
          <v-col v-if="user.name !== name" cols="12">
            <h3>{{ name }}'s kalender</h3>
          </v-col>
          <v-col cols="6" md="2">
            <v-select v-model="viewType" :items="calenderMode" @change="changeView" dense outlined hide-details label="Kalender format" />
          </v-col>
          <v-col cols="6" md="auto" class="d-flex align-center ml-4">
            <!-- <v-radio-group v-model="showType" row hide-details class="my-auto">
              <v-radio value="" label="Vis alle" />
              <v-radio value="theory" label="Kun teori" />
              <v-radio value="practical" label="Kun praktisk" />
            </v-radio-group> -->
            <v-checkbox hide-details="auto" v-model="showCancelled" @change="reRender" class="my-auto" label="Vis aflyste" />
          </v-col>
          <v-col class="ml-auto text-right" align-self="end">
            <r-btn class="mr-4" v-if="viewType == 'timeGridDay'" :soft="true" @click="saveStandardDay"> Gem standard </r-btn>
            <r-btn class="mr-4" :soft="true" @click="today"> I dag </r-btn>
            <r-btn v-if="isStaff" :action="true" @click="createCalenderEvent"> Tilføj </r-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet class="scrollable calendar">
        <full-calendar ref="fullCalendarRef" :options="calendarOptions" />
      </v-sheet>
    </v-sheet>
    <v-sheet v-if="!$vuetify.breakpoint.mobile" width="16vw" class="px-2 pt-4">
      <p class="ma-0 pa-0">Vælg lektionsplan:</p>
      <v-select
        :items="modulePlans"
        item-text="name"
        v-model="selectedModulePlan"
        hide-details="auto"
        dense
        return-object
        @change="getModules"
        outlined
      />
      <v-divider class="mt-4 mb-2" />
      <div class="my-10 text-center">
        <p class="my-0">Træk modul til dato og slip</p>
      </div>
      <div class="scrollable">
        <r-btn id="standardDay" v-show="standardDay" rounded draggable="true" large width="14vw" class="mb-2 text-center draggable-custom-btn">
          <p class="ma-0">Standard dag</p>
        </r-btn>
        <r-btn
          id="openSlot"
          rounded
          draggable="true"
          :color="$util.CalenderLessonColor(2, true)"
          large
          width="14vw"
          class="my-2 text-center draggable-custom-btn"
        >
          <p class="ma-0">Åben tid praktisk</p>
        </r-btn>
        <!-- <r-btn
          v-for="(module, index) in modules"
          v-show="showType == 'theory' ? true : showType == '' ? true : false"
          :key="index"
          :id="module.id"
          rounded
          draggable="true"
          :color="$util.CalenderLessonColor(1, true)"
          large
          width="14vw"
          class="my-2 text-center draggable-custom-btn lighten-2"
        >
          <p class="ma-0">{{ module.name }} teori</p>
        </r-btn> -->
      </div>
    </v-sheet>
    <calendar-panel ref="CalendarPanelRef" @update-calender="getEvents" />
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';
import CalendarPanel from '@/components/dialogs/CalendarPanel.vue';
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import daLocale from '@fullcalendar/core/locales/da';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { DateTime, Duration } from 'luxon';
import SmartInterval from 'smartinterval';

export default {
  name: 'Calendar',
  components: {
    CalendarPanel,
    FullCalendar,
  },
  props: {
    UserId: String,
  },
  data: () => ({
    events: [],
    name: '',
    calenderMode: [
      { text: 'Dag', value: 'timeGridDay' },
      { text: 'Uge', value: 'timeGridWeek' },
      { text: 'Måned', value: 'dayGridMonth' },
      { text: '3 Måneder', value: 'dayGridMonth_3' },
    ],
    viewType: 'dayGridMonth',
    showCancelled: false,
    modulePlans: [],
    modules: [],
    selectedModulePlan: null,
    selectedDate: null,
    standardDay: null,
    dataFetcher: null,
    calendarHeight: '75vh',
  }),
  watch: {
    [`$route.params`](newRoute, oldRoute) {
      if (newRoute.UserId !== oldRoute.UserId) {
        this.getEvents();
        this.getModulePlans();
        this.getStandardDay();
      }
    },
    ['viewType'](val) {
      this.calendarHeight = val == 'dayGridMonth_3' ? null : '75vh';
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'isStaff', 'user', 'darkMode', 'currentOrgId']),
    filteredEvents() {
      let events = this.events;

      if (!this.showCancelled) {
        events = events.filter(x => x.status != 3);
      }

      return events.filter(x => x.type !== 1);
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        customButtons: {
          customNextBtn: {
            icon: 'chevron-right',
            click: this.next,
          },
          customPrevBtn: {
            icon: 'chevron-left',
            click: this.prev,
          },
        },
        headerToolbar: {
          left: false,
          center: 'customPrevBtn title customNextBtn',
          right: false,
        },
        moreLinkContent: this.showMore,
        height: this.calendarHeight,
        locales: [daLocale],
        locale: 'da',
        firstDay: 1,
        initialView: 'dayGridMonth',
        editable: true,
        droppable: true,
        selectable: true,
        allDaySlot: false,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        events: this.filteredEvents,
        eventDrop: this.eventDragDrop,
        eventDataTransform: this.parseEvent,
        eventClick: this.showEvent,
        drop: this.dropEvent,
        select: this.setDate,
        fixedWeekCount: false,
        slotEventOverlap: false,
        weekNumbers: true,
        weekNumberFormat: {
          week: 'short',
        },

        views: {
          dayGridMonth_3: {
            type: 'dayGridMonth',
            duration: { months: 3 },
          },
        },
      };
    },
  },
  methods: {
    MarkDistinctMonths() {
      this.$refs.fullCalendarRef.getApi().getEventById('MonthSeperator')?.remove();
      if (this.$refs.fullCalendarRef.getApi().view.type == 'dayGridMonth_3') {
        let currentRange = this.$refs.fullCalendarRef.getApi().getCurrentData().dateProfile.currentRange;
        let start = DateTime.fromJSDate(currentRange.start).plus({ months: 1 }).toISODate();
        let end = DateTime.fromJSDate(currentRange.end).minus({ months: 1 }).toISODate();

        this.$refs.fullCalendarRef.getApi().addEvent({
          id: 'MonthSeperator',
          start: start,
          end: end,
          display: 'background',
          color: '#E0E0E0',
        });
      }
    },
    async saveStandardDay() {
      var eventTemplates = this.events
        .filter(x => DateTime.fromISO(x.start).hasSame(DateTime.fromJSDate(this.selectedDate), 'day') && (x.type == 1 || x.type == 2))
        .map(x => ({
          lessonType: x.type,
          start: x.start,
          end: x.end,
          instructorId: x.instructorId,
          moduleId: x.moduleId,
        }));
      await apiService.saveStandardDay(eventTemplates);
      await this.getStandardDay();
    },
    async getStandardDay() {
      var result = await apiService.getStandardDay();

      if (result?.length > 0) {
        this.standardDay = result;
      }
    },
    setDate(date) {
      this.selectedDate = date.start;
      this.addBackgroundEvent(date.start);
    },
    today() {
      this.selectedDate = DateTime.now().toISO();
      this.$refs.fullCalendarRef.getApi().today();
      this.MarkDistinctMonths();
    },
    next() {
      this.$refs.fullCalendarRef.getApi().next();
      if (this.$refs.fullCalendarRef.getApi().view.type === 'timeGridDay') {
        this.selectedDate = DateTime.fromJSDate(this.selectedDate).plus({ days: 1 }).toJSDate();
      }
      this.MarkDistinctMonths();
    },
    prev() {
      this.$refs.fullCalendarRef.getApi().prev();
      if (this.$refs.fullCalendarRef.getApi().view.type === 'timeGridDay') {
        this.selectedDate = DateTime.fromJSDate(this.selectedDate).plus({ days: -1 }).toJSDate();
      }
      this.MarkDistinctMonths();
    },
    changeView() {
      if (this.viewType) this.$refs.fullCalendarRef.getApi().changeView(this.viewType);
      if (this.selectedDate) {
        this.$refs.fullCalendarRef.getApi().gotoDate(this.selectedDate);
      }
      this.addBackgroundEvent(this.selectedDate);
      this.MarkDistinctMonths();
    },
    reRender() {
      this.$refs.fullCalendarRef.getApi().refetchEvents();
    },
    parseEvent(eventData) {
      return {
        title: eventData.studentEvents[0]?.studentName ?? eventData.comment ?? 'Åben tid',
        id: eventData.id,
        start: eventData.start,
        end: eventData.end,
        color: this.$util.CalenderLessonColor(eventData.type, eventData.isPublic),
      };
    },
    showMore(data) {
      return data.shortText + ' Flere';
    },
    createCalenderEvent() {
      this.$refs.CalendarPanelRef.createNewEvent(this.selectedDate);
    },
    showEvent(eventClickInfo) {
      let clickedEvent = this.events.find(x => x.id == eventClickInfo.event.id);
      this.$refs.CalendarPanelRef.showEvent(clickedEvent);
      document.getElementsByClassName('fc-popover').item(0)?.remove();
    },
    async eventDragDrop(eventInfo) {
      let event = this.events.find(item => item.id === eventInfo.event.id);
      let newEvent = { ...event };
      newEvent.start = eventInfo.event.startStr;
      newEvent.end = eventInfo.event.endStr;
      if (newEvent.moduleId == '00000000-0000-0000-0000-000000000000') newEvent.moduleId = null;

      await apiService.updateEventTime(newEvent);
      this.getEvents();
    },
    dropEvent(eventInfo) {
      if (eventInfo.draggedEl.id != 'standardDay') {
        let eventModuleId = eventInfo.draggedEl.id;
        let eventStart = eventInfo.dateStr;
        this.$refs.CalendarPanelRef.createDeterminedEvent(eventModuleId, eventStart);
      } else {
        this.createStandardDayEvents(eventInfo.date);
      }
    },
    async createStandardDayEvents(date) {
      let eventDate = DateTime.fromJSDate(date);

      for (let index = 0; index < this.standardDay.length; index++) {
        const eventTemplate = this.standardDay[index];
        await apiService.updateEvent({
          instructorId: eventTemplate.instructorId,
          moduleId: eventTemplate.lessonType == 2 ? null : eventTemplate.moduleId,
          organizationId: this.currentOrgId,
          start: DateTime.fromISO(eventTemplate.start).set({ year: eventDate.year, month: eventDate.month, day: eventDate.day }).toISO(),
          end: DateTime.fromISO(eventTemplate.end).set({ year: eventDate.year, month: eventDate.month, day: eventDate.day }).toISO(),
          type: eventTemplate.lessonType,
        });
      }
      this.getEvents();
    },
    async getEvents() {
      var response = await apiService.getUserEventsById(this.UserId);
      this.name = response.name;
      this.events = response.events;
    },
    async getModulePlans() {
      if (this.currentOrgId) {
        this.modulePlans = await apiService.getModulePlanList(this.currentOrgId);
        this.selectedModulePlan = this.modulePlans.length ? this.modulePlans[0] : null;
        this.getModules();
      }
    },
    async getModules() {
      let response = await apiService.getModulesByModulePlan(this.selectedModulePlan.id);
      this.modules = response.filter(x => x.lessonType == 1);

      this.$nextTick(this.createDynamicDraggables);
    },
    addBackgroundEvent(date) {
      this.$refs.fullCalendarRef.getApi().getEventById('Background')?.remove();
      this.$refs.fullCalendarRef.getApi().addEvent({
        id: 'Background',
        start: date,
        allDay: true,
        display: 'background',
        color: '#65CEF0',
      });
    },

    createStaticDraggables() {
      new Draggable(document.getElementById('openSlot'), {
        eventData: () => {
          return {
            create: false,
            duration: this.viewType === 'dayGridMonth' ? null : '01:30',
          };
        },
      });

      new Draggable(document.getElementById('standardDay'), {
        eventData: () => {
          return {
            create: false,
            allDay: true,
          };
        },
      });

      this.$refs.fullCalendarRef.getApi().render();
    },

    createDynamicDraggables() {
      this.modules.forEach(module => {
        new Draggable(document.getElementById(module.id), {
          eventData: () => {
            return {
              create: false,
              duration:
                this.viewType === 'dayGridMonth'
                  ? null
                  : module.minimumDuration
                  ? Duration.fromObject({ minutes: 45 * module.minimumDuration }).toFormat('hh:mm')
                  : '01:30',
            };
          },
        });
      });

      this.$refs.fullCalendarRef.getApi().render();
    },
  },
  created() {
    this.dataFetcher = new SmartInterval(async () => {
      await this.getEvents();
    }, 30000);
    this.dataFetcher.start();

    this.getModulePlans();
    this.getStandardDay();
  },
  mounted() {
    this.createStaticDraggables();
  },
  beforeDestroy() {
    this.dataFetcher.stop();
  },
};
</script>


<style lang="css">
.v-application .primary {
  background-color: blue !important;
  border-color: blue !important;
}
.r-btn--fab.v-size--default {
  height: 35px;
  width: 35px;
}
.v-calendar-daily__scroll-area::-webkit-scrollbar {
  width: 16px;
}

.v-calendar-daily__scroll-area::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}
.fc-event {
  cursor: pointer;
}

.fc-toolbar-chunk {
  display: flex;
}

.fc-toolbar-title {
  margin: 0% !important;
  width: 20vw !important;
  text-align: center;
}

.fc-button {
  background-color: white !important;
  margin: 0% !important;
  border: none !important;
  color: black;
}

.fc-icon {
  color: black;
}

.draggable-custom-btn::before {
  display: none !important;
}
.scrollable {
  overflow-y: auto;
  height: 65vh;
}

.calendar {
  height: 80vh !important;
}
</style>

